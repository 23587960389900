import Vue from "vue";
import VueRouter from "vue-router";
import { findUserIntegralGarden } from "@/api/SignIn";
import { Dialog } from "vant";
// import store from "../store/index";
import { addTPv } from "@/api/index";
import { lWeChatShare } from "@/utils/WeChat/jwx";
import getJSSDK from "@/utils/wxShare";

import { EventHub } from "@/components/eventHub.js";
Vue.use(VueRouter);
// import { Toast } from "vant";
// let spinRoute = {
//   show() {
//     //加载中显示loading组件
//     Toast.loading({
//       message: "加载中...",
//       forbidClick: true,
//       duration: 0, // 持续展示 toast
//     });
//   },
//   resolve(resolve) {
//     //加载完成隐藏loading组件
//     return (component) => {
//       setTimeout(() => {
//         Toast.clear();
//         resolve(component);
//       }, 10);
//     };
//   },
// };
const routes = [
  {
    path: "/login/privacy",
    name: "privacy",
    meta: {
      title: "隐私条例",
    },
    component: () => import("@/views/Login/common/privacy.vue"),
  },
  {
      path: "/login/privacy20220901",
          name: "privacy",
      meta: {
      title: "隐私条例20220901",
  },
      component: () => import("@/views/Login/common/privacy20220901.vue"),
  },
  {
    path: "/login/userProtocol",
    name: "userProtocol",
    meta: {
      title: "用户协议",
    },
    component: () => import("@/views/Login/common/userProtocol.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "中智薪税",
    },
    component: () => import("@/views/About.vue"),
  },
  {
    path: "/ProductServe",
    name: "ProductServe",
    meta: {
      title: "产品与服务",
    },
    component: () => import("@/views/ProductServe/index.vue"),
  },
  {
    path: "/productServe/details",
    name: "productServeDetails",
    meta: {
      title: "产品与服务",
    },
    component: () => import("@/views/ProductServe/Details.vue"),
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    meta: {
      title: "联系我们",
    },
    component: () => import("@/views/ContactUs/index.vue"),
  },
  {
    path: "/contactUs/success",
    name: "contactUsSuccess",
    meta: {
      title: "联系我们",
    },
    component: () => import("@/views/ContactUs/Success.vue"),
  },
  {
    path: "/cooperationCase",
    name: "CooperationCase",
    meta: {
      title: "合作伙伴",
      isTPv: true,
    },
    component: () => import("@/views/CooperationCase.vue"),
  },
  {
    path: "/utilities",
    name: "Utilities",
    meta: {
      title: "线上服务",
    },
    component: () => import("@/views/Utilities/index.vue"),
  },
  {
    path: "/calculator/moon",
    name: "CalculatorMoon",
    meta: {
      title: "薪税计算器",
      isTPv: true,
    },
    component: () => import("@/views/Calculator/Moon/index.vue"),
  },
  {
    path: "/calculator/socialSecurity",
    name: "socialSecurity",
    meta: {
      title: "社保缴纳",
    },
    component: () => import("@/views/Calculator/Moon/socialSecurity.vue"),
  },
  {
    path: "/calculator/providentFund",
    name: "providentFund",
    meta: {
      title: "公积金缴纳",
    },
    component: () => import("@/views/Calculator/Moon/providentFund.vue"),
  },
  {
    path: "/calculator/special",
    name: "special",
    meta: {
      title: "个税专项扣除",
    },
    component: () => import("@/views/Calculator/Moon/special.vue"),
  },
  {
    path: "/calculator/compute",
    name: "calculatorCompute",
    meta: {
      title: "计算结果",
    },
    component: () => import("@/views/Calculator/compute/index.vue"),
  },
  {
    path: "/calculator/year",
    name: "CalculatorYear",
    meta: {
      title: "薪税计算器",
      isTPv: true,
    },
    component: () => import("@/views/Calculator/Year/index.vue"),
  },

  {
    path: "/signIn",
    name: "SignInIndex",
    meta: {
      title: "成长值 UP",
      isTPv: true,
      roles: true,
    },
    component: () => import("@/views/SignIn/index.vue"),
  },
  {
    path: "/signIn/giftGrowth",
    name: "GiftGrowth",
    meta: {
      title: "成长权益",
      roles: true,
    },
    component: () => import("@/views/SignIn/GiftGrowth.vue"),
  },

  {
    path: "/signIn/giftGrowth/Result",
    name: "GiftGrowthResult",
    meta: {
      title: "成长权益",
      roles: true,
    },
    component: () => import("@/views/SignIn/Result.vue"),
  },
  {
    path: "/signIn/GrowthValueUse",
    name: "GrowthValueUse",
    meta: {
      title: "成长权益使用",
      roles: true,
    },
    component: () => import("@/views/SignIn/GrowthValueUse.vue"),
  },
  {
    path: "/peopleCenter",
    name: "PeopleCenter",
    meta: {
      title: "我的薪税",
      roles: true,
    },
    component: () => import("@/views/PeopleCenter/index.vue"),
  },
  {
    path: "/peopleCenter/personalInfo",
    name: "PersonalInfo",
    meta: {
      title: "个人信息",
      roles: true,
    },
    component: () => import("@/views/PeopleCenter/PersonalInfo.vue"),
  },
  {
    path: "/peopleCenter/order",
    name: "PeopleCenterOrder",
    meta: {
      title: "我的订单",
      roles: true,
    },
    component: () => import("@/views/PeopleCenter/Order.vue"),
  },
  {
    path: "/peopleCenter/apply",
    name: "PeopleCenterApply",
    meta: {
      title: "我的申请",
      roles: true,
    },
    component: () => import("@/views/PeopleCenter/Apply.vue"),
  },
  {
    path: "/policyLibrary",
    name: "PolicyLibrary",
    meta: {
      title: "薪税知道",
      isTPv: true,
    },
    component: () => import("@/views/PolicyLibrary/index.vue"),
  },
  {
    path: "/policyLibrary/Result",
    name: "PolicyLibraryResult",
    meta: {
      title: "薪税知道",
    },
    component: () => import("@/views/PolicyLibrary/components/Result.vue"),
  },
  {
    path: "/policyLibrary/questions",
    name: "PolicyLibraryQuestions",
    meta: {
      title: "薪税知道",
      roles: true,
    },
    component: () => import("@/views/PolicyLibrary/Questions.vue"),
  },

  {
    path: "/policyLibrary/freeCourse",
    name: "FreeCourse",
    meta: {
      title: "政策库",
    },
    component: () => import("@/views/PolicyLibrary/FreeCourse.vue"),
  },
  {
    path: "/salaryQueryv1",
    name: "salaryQuery",
    meta: {
      title: "薪税查",
    },
    component: () => import("@/views/SalaryQuery/index.vue"),
  },
  {
    path: "/salaryQueryv1/region/:queryCodeId/:queryType",
    name: "QueryRegion",
    meta: {
      title: "薪税查",
    },
    component: () => import("@/views/SalaryQuery/QueryRegion.vue"),
  },
  {
    path: "/salaryQueryv1/certificate/:queryCodeId/:queryType",
    name: "QueryCertificate",
    meta: {
      title: "薪税查",
    },
    component: () => import("@/views/SalaryQuery/QueryCertificate.vue"),
  },
  {
      path: "/salaryQueryv1/policy/:queryCodeId/:queryType",
      name: "QueryPolicy",
      meta: {
          title: "薪税查",
      },
      component: () => import("@/views/SalaryQuery/QueryPolicy.vue"),
  },
  {
    path: "/salaryQueryv1/result/:queryCodeId/:queryType/:valueCity",
    name: "QueryResult",
    meta: {
      title: "薪税查",
    },
    component: () => import("@/views/SalaryQuery/QueryResult.vue"),
  },
  {
      path: "/salaryQueryv2/result/:id/:queryCodeId/:queryType",
      name: "QueryResultPolicy",
      meta: {
          title: "薪税查",
      },
      component: () => import("@/views/SalaryQuery/QueryResultPolicy.vue"),
  },
  {
    path: "/salaryQueryv1/feedback/:queryCodeId/:queryType",
    name: "QueryFeedback",
    meta: {
      title: "意见反馈",
    },
    component: () => import("@/views/SalaryQuery/QueryFeedback.vue"),
  },
  {
    path: "/postStation",
    name: "FreeCourse",
    meta: {
      title: "政策驿站",
      isTPv: true,
    },
    component: () => import("@/views/PostStation.vue"),
  },
  {
    path: "/policyLibrary/articleDetails",
    name: "ArticleDetails",
    meta: {
      title: "精选文章",
      customize: true,
    },
    component: () => import("@/views/PolicyLibrary/ArticleDetails.vue"),
  },
  {
    path: "/result",
    name: "Result",
    meta: {
      title: "结果提示页面",
      customize: true,
    },
    component: () => import("@/views/Result.vue"),
  },

  {
    path: "/m1",
    name: "m1",
    meta: {
      title: "中智薪税",
      customize: true,
    },
    component: () => import("@/views/Model/M1.vue"),
  },
  {
    path: "/m2",
    name: "m2",
    meta: {
      title: "中智薪税",
      customize: true,
    },
    component: () => import("@/views/Model/M2.vue"),
  },
  {
    path: "/m3",
    name: "m3",
    meta: {
      title: "中智薪税",
      customize: true,
    },
    component: () => import("@/views/Model/M3.vue"),
  },
  {
    path: "/m4",
    name: "m4",
    meta: {
      title: "中智薪税",
      customize: true,
    },
    component: () => import("@/views/Model/M4.vue"),
  },
  {
    path: "/m5",
    name: "m5",
    meta: {
      title: "中智薪税",
      customize: true,
    },
    component: () => import("@/views/Model/M5.vue"),
  },
  // {
  //   path: "/m6",
  //   name: "m6",
  //   meta: {
  //     title: "中智薪税",
  //     customize: true,
  //   },
  //   component: () => import("@/views/Model/M6.vue"),
  // },
  { path: "*", redirect: "/peopleCenter", hidden: true },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.customize) {
    getJSSDK({
      title: to.meta.title || "中智薪税",
      link: to.fullPath,
      name: to.name
    });
  }

  // lWeChatShare(
  //   {},
  //   {
  //     title: to.meta.title || "中智薪税",
  //     // desc: "",
  //     link: to.fullPath,
  //   },
  //   () => {}
  // );
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title || "中智薪税";
  } else if (to.query.title) {
    document.title = decodeURIComponent(to.query.title);
  }
  // 需要判断是否需要openid页面
  if (to.meta.roles) {
    if (localStorage.getItem("status") == 102) {
      next();
    } else {
      localStorage.setItem("historyRoute", to.fullPath);
      next({ path: "/login" });
    }
  } else {
    next();
  }
  if (to.meta.isTPv) {
    let pvMenuName = to.meta.title;
    let pvOpenid = localStorage.getItem("code") || "";
    let pvIp = localStorage.getItem("Ip") || "";
    addTPv({ pvMenuName, pvOpenid, pvIp }).then((res) => {});
  }
  if (typeof to.matched[0]?.components.default === "function") {
    EventHub.$emit("asyncComponentLoading"); // 启动进度条
  }
  // else if (to.meta.isGrade) {
  //   let openId = localStorage.getItem("code") || "";
  //   if (localStorage.getItem("status") == 102 && openId) {
  //     findUserIntegralGarden({ openId })
  //       .then((res) => {
  //         let grade = Number(res.data.data.twxUser.garden?.split("V")[1]);
  //         if (grade > 1) {
  //           next();
  //         } else {
  //           Dialog.alert({
  //             message: "抱歉，您的等级不够，暂不能使用计算器功能",
  //           }).then(() => {
  //             // on close
  //             // next(false);
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         // localStorage.setItem("historyRoute", to.fullPath);
  //         // next({ path: "/login" });
  //       });
  //   } else {
  //     localStorage.setItem("historyRoute", to.fullPath);
  //     next({ path: "/login" });
  //   }
  // }
});
router.beforeResolve((to, from, next) => {
  EventHub.$emit("asyncComponentLoaded"); // 停止进度条
  next();
});
router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})
export default router;
