import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

//引入state状态
export default new Vuex.Store({
  state: {
    historyRoute: "",
  },
  getters: {
    SER_HISTORYROUTE: (state) => state.historyRoute,
  },
  mutations: {
    GET_HISTORYROUTE(state, data) {
      state.historyRoute = data;
    },
  },
  actions: {},
  modules: {},
});
