<template>
  <div class="ProgressBar" v-if="isLoading">
    <h2>中智薪税</h2>
    <div
      :class="{
        'loading-container': true,
        loading: isLoading,
        visible: isVisible,
      }"
    >
      <div class="loader" :style="{ width: progress + '%' }">
        <div class="light"></div>
      </div>
      <div class="glow"></div>
    </div>
  </div>
</template>
<script>
import random from "lodash.random";
import { EventHub } from "./eventHub.js";
// 假设加载将在此时间内完成。
const defaultDuration = 8000;
// 更新频率
const defaultInterval = 1000;
// 取值范围 0 - 1. 每个时间间隔进度增长多少
const variation = 0.5;
// 0 - 100. 进度条应该从多少开始。
const startingPoint = 0;
// 限制进度条到达加载完成之前的距离
const endingPoint = 90;
export default {
  name: "ProgressBar",

  data: () => ({
    isLoading: true, // 加载完成后，开始逐渐消失
    isVisible: false, // 完成动画后，设置 display: none
    progress: startingPoint,
    timeoutId: undefined,
  }),

  mounted() {
    EventHub.$on("asyncComponentLoading", this.start);
    EventHub.$on("asyncComponentLoaded", this.stop);
    this.start();
  },

  methods: {
    start() {
      this.isLoading = true;
      this.isVisible = true;
      this.progress = startingPoint;
      this.loop();
    },

    loop() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (this.progress >= endingPoint) {
        return;
      }
      const size =
        (endingPoint - startingPoint) / (defaultDuration / defaultInterval);
      const p = Math.round(
        this.progress + random(size * (1 - variation), size * (1 + variation))
      );
      this.progress = Math.min(p, endingPoint);
      this.timeoutId = setTimeout(
        this.loop,
        random(
          defaultInterval * (1 - variation),
          defaultInterval * (1 + variation)
        )
      );
    },

    stop() {
      this.isLoading = false;
      this.progress = 100;
      clearTimeout(this.timeoutId);
      const self = this;
      setTimeout(() => {
        if (!self.isLoading) {
          self.isVisible = false;
        }
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.ProgressBar {
  width: 100%;
  min-height: 100vh;
  padding: 60px;
  background: #fff;
  padding-top: 60%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  h2 {
    text-align: center;
    color: #004797;
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 28px;
  }
}
.loading-container {
  font-size: 0;
  /* position: fixed;
  top: 0;
  left: 0; */
  height: 5px;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  display: none;
  z-index: 100;
  transition: opacity 200;
}

.loading-container.visible {
  display: block;
}
.loading-container.loading {
  opacity: 1;
}

.loader {
  background: #23d6d6;
  display: inline-block;
  height: 100%;
  width: 50%;
  overflow: hidden;
  border-radius: 0 0 5px 0;
  transition: 1s;
  /* transition: 200 width ease-out ; */
}

.loader > .light {
  float: right;
  height: 100%;
  width: 20%;
  background-image: linear-gradient(to right, #23d6d6, #29ffff, #23d6d6);
  animation: loading-animation 2s ease-in infinite;
}

.glow {
  display: inline-block;
  height: 100%;
  width: 30px;
  margin-left: -30px;
  border-radius: 0 0 5px 0;
  box-shadow: 0 0 10px #23d6d6;
}

@keyframes loading-animation {
  0% {
    margin-right: 100%;
  }
  50% {
    margin-right: 100%;
  }
  100% {
    margin-right: -10%;
  }
}
</style>
