import axios from "axios";
// import { ElMessage } from "element-plus";

const service = axios.create({
  // process.env.VUE_APP_API_URL === 'development', // 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    // let token = localStorage.getItem("CELIX_TOKEN");
    // if (!config.headers.hasOwnProperty("Authorization") && token) {
    //   config.headers.Authorization = token;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    switch (response.data.code) {
      case 201:
        // ElMessage.error(response.data.errorMessage);
        break;
      default:
        return response;
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误(400)";
          break;
        case 401:
          error.message = "未授权，请重新登录(401)";
          break;

        case 403:
          error.message = "拒绝访问(403)";
          break;

        case 406:
          error.message = "无权限访问(406)";
          break;

        case 404:
          error.message = "请求出错(404)";
          break;

        case 408:
          error.message = "请求超时(408)";
          break;

        case 500:
          error.message = "服务器错误(500)";
          // error.response.data.message === "登录时间失效！"
          //   ? router.push("/login")
          //   : Message.warning(error.message + "请重新登录");
          break;

        case 501:
          error.message = "服务未实现(501)";
          break;

        case 502:
          error.message = "网络错误(502)";
          break;

        case 503:
          error.message = "服务不可用(503)";
          break;

        case 504:
          error.message = "网络超时(504)";
          break;

        case 505:
          error.message = "HTTP版本不受支持(505)";
          break;

        default:
          error.message = `连接出错(${error.response.status})!`;
      }
    } else {
      error.message = "连接服务器失败!";
    }
    return Promise.reject(error);
  }
);

export default service;
