import request from "@/utils/request";
import qs from "qs";
// 获取用户成长值 等级信息
export const findUserIntegralGarden = (params) => {
  return request({
    url: "wx/findUserIntegralGarden",
    method: "get",
    params,
  });
};
// 每日签到
export const dailyCheck = (data) => {
  return request({
    url: "wx/dailyCheck",
    method: "post",
    data: qs.stringify(data),
  });
};
// 每日签到
export const findAllActivity = (params) => {
  return request({
    url: "wx/findAllActivity",
    method: "get",
    params,
  });
};
// 奖品列表
export const exchangePrizeList = (params) => {
  return request({
    url: "wx/exchangePrizeList",
    method: "get",
    params,
  });
};
// 兑换奖品
export const exchangePrize = (params) => {
  return request({
    url: "wx/exchangePrize",
    method: "get",
    params,
  });
};
// 获取用户兑换历史记录
export const findTHistoryInterestsByOpenId = (params) => {
  return request({
    url: "wx/findTHistoryInterestsByOpenId",
    method: "get",
    params,
  });
};
// 获取用户成长值历史记录
export const findTHistoryIntegralByOpenId = (params) => {
  return request({
    url: "wx/findTHistoryIntegralByOpenId",
    method: "get",
    params,
  });
};
// 检查用户签到状态
export const dailyCheckTheDay = (params) => {
  return request({
    url: "wx/dailyCheckTheDay",
    method: "get",
    params,
  });
};
// 今日成长值
export const theDayGetUpValue = (params) => {
  return request({
    url: "wx/theDayGetUpValue",
    method: "get",
    params,
  });
};
// 我是成长规则
export const getActivityRule = (params) => {
  return request({
    url: "wx/getActivityRule",
    method: "get",
    params,
  });
};
