/* eslint-disable*/
import wx from "@/utils/WeChat/jweixin-1.3.2";
import { webWX } from "@/api/index";
import { shareCheck } from "@/api/index";
const jsApiList = [
  "onMenuShareAppMessage",
  "onMenuShareTimeline",
  "onMenuShareQQ",
  "onMenuShareWeibo",
  "hideMenuItems",
  "hideAllNonBaseMenuItem"
];
const envUrl = process.env.VUE_APP_API_URL;
//要用到微信API
function getJSSDK(dataForWeixin) {
  if (typeof window.entryUrl === "undefined" || window.entryUrl === "") {
    window.entryUrl = location.href.split("#")[0];
  }
  // 进行签名的时候  Android 不用使用之前的链接， ios 需要
  let url = /(Android)/i.test(navigator.userAgent)
    ? location.href.split("#")[0]
    : window.entryUrl;
  webWX({ url }).then((res) => {
    const { data } = res.data;
    let link =
      window.location.origin +
      window.location.pathname +
      "#" +
      dataForWeixin.link;
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: data.appid, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.noncestr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名
      jsApiList: jsApiList, // 必填，需要使用的JS接口列表
      success: () => {
        alert("success");
      },
    });
    wx.ready(function () {
      if (dataForWeixin.name == 'QueryFeedback') {
        wx.hideAllNonBaseMenuItem();
      } else {
        const share = {
          title: dataForWeixin.title || "中智薪税", // 分享标题
          desc: dataForWeixin.desc || "薪税数据随时查  HR的好帮手", // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          // imgUrl: "https://zzxs.xinyanhecheng.com/headImg/fenxiang.png", // 分享图标
          // imgUrl: "https://wms.ciicpt.com.cn/headImg/fenxiang.png", // 分享图标
          imgUrl: "https://ciicfchrsaas.ciicpt.com.cn/xinshuilogo.png", // 分享图标
          // imgUrl: `require('@/assets/img/fenxiang.jpg')`, // 分享图标
          // title: "分享标题（朋友圈只显示标题）",
          // desc: "分享内容",
          // imgUrl: "图片URL",
          // link: "分享链接（最好是后台JS安全域名）",
          success: function () {
            // hideMaskLayer(); // 分享成功，隐藏引导用户分享的浮层
          },
          cancel: function () {},
        };
        const friendShare = {
          title: dataForWeixin.title || "中智薪税", // 分享标题
          desc: dataForWeixin.desc || "薪税数据随时查  HR的好帮手", // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: "https://ciicfchrsaas.ciicpt.com.cn/xinshuilogo.png", // 分享图标
          // imgUrl: `require('@/assets/img/fenxiang.jpg')`, // 分享图标
          // imgUrl: "https://wms.ciicpt.com.cn/headImg/fenxiang.png", // 分享图标
          // imgUrl: "https://zzxs.xinyanhecheng.com/headImg/fenxiang.png", // 分享图标
          success: function () {
            let openId = localStorage.getItem("code");
            let type = "5";
            let url = link;
            shareCheck({ openId, type, url }).then((res) => {});
            // hideMaskLayer(); // 分享成功，隐藏引导用户分享的浮层
          },
          cancel: function () {},
        };
        wx.onMenuShareAppMessage(friendShare); // 微信好友
        wx.onMenuShareTimeline(share); // 朋友圈
        wx.onMenuShareQQ(share); // QQ
        wx.onMenuShareQZone(share); // QQ空间
        wx.onMenuShareWeibo(share); // 腾讯微博
      }
    });
  });
}
export default getJSSDK; // 获取JSSDK
