import request from "../utils/request";
import qs from "qs";
//  获取用户信息
export const getCodeInfo = (params) => {
  return request({
    url: "wx/getCodeInfo",
    method: "get",
    params,
  });
};
//  发送短信验证码
export const sendSMS = (params) => {
  return request({
    url: "wx/sendSMS",
    method: "get",
    params,
  });
};
// 用户登录
export const wxuserlogin = (data) => {
  return request({
    url: "wx/wxuserlogin",
    method: "post",
    data: qs.stringify(data),
  });
};
// 埋点
export const addTPv = (data) => {
  return request({
    url: "wx/addTPv",
    method: "post",
    data: qs.stringify(data),
  });
};
// 活动
export const activityCheck = (params) => {
  return request({
    url: "wx/activityCheck",
    method: "get",
    params,
  });
};
// 权限接口
export const webWX = (params) => {
  return request({
    url: "wx/webWX",
    method: "get",
    params,
  });
};
// 转发活动
export const shareCheck = (params) => {
  return request({
    url: "wx/shareCheck",
    method: "get",
    params,
  });
};
