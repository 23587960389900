<template>
  <div id="app">
    <progress-bar></progress-bar>
    <router-view />
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
export default {
  components: {
    ProgressBar
  }
};
</script>
<style lang="scss"></style>
